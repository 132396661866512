.favlec_wrapper {
  padding: 4% 2% 4% 2%;
  @media screen and (max-width: 600px) {
    padding: 0% 2% 4% 2%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  .favlec_img_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    .favlec_text {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    
      text-align: center;
    }
  }
  .favlec_button {
    background: #ddff2b;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 198px;
    height: 56px;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    cursor: pointer;
  }

  .favlecturers_widget {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    @media screen and (max-width: 1238px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
    @media screen and (max-width: 1010px) {
      gap: 3rem;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1em;
    }
    @media screen and (max-width: 650px) {
      gap: 0.5em;
    }

    @media screen and (max-width: 512px) {
      // justify-content: center;
    }
    //flex-direction: row;
    //align-items: center;
    //justify-content: space-between;
    gap: 4rem;
    flex-wrap: wrap;
    // margin-top: 3rem;
    @media screen and (max-width: 615px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 2rem;
    }
    .lecturers_item {
      cursor: pointer;
    }
  }

  .load_m {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}
