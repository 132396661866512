@mixin input_style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  height: 40px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5294px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  line-height: 22px;
  color: white;
  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.5294px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    line-height: 22px;
    color: #5e5e5e;
  }
}

.addplay_wrapper {
  width: 100%;
  height: 100%;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  font-family: "Poppins";
  right: 0;
  z-index: 100;
  position: fixed;
 

  .let {
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
  }

  .swipeDown {
    animation-name: swipeUp;
    -webkit-animation-name: swipeUp;
  }

  @keyframes swipeUp {
    0% {
      transform: translateY(-100%);
    }

    100% {
      transform: translateY(0px);
    }
  }

  @-webkit-keyframes swipeUp {
    0% {
      -webkit-transform: translateY(-100%);
    }

    100% {
      -webkit-transform: translateY(0px);
    }
  }

  .smaller_wrapper {
    transform: ease-in;
    position: absolute;
    margin: auto;
    height: 250px;
    overflow: hidden;
    inset: 0;
    width: 450px;
    display: flex;
    flex-direction: column;

    border-radius: 8px;
   
    padding: 1rem;
    font-size: 16px;

    @media screen and (max-width: 615px) {
      font-size: 14px;
      width: 80%;
    }

    .close_image {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 4px;
      top: 5px;

      .close_img_sz {
        width: 100%;
        height: 100%;
      }
    }

    .add_play_header {
      width: 100%;
      padding: 3% 4% 2% 4%;
      font-weight: 500;
    
      border-bottom: 1px solid#353535;
      margin-bottom: 1.5rem;
    }

    .playlist_name {
      @include input_style;
      padding-left: 1rem;
      margin-bottom: 1.5rem;
    }

    .private_public {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      //color:white;
      @media screen and (max-width: 615px) {
        font-size: 10px;
      }

      /* The container */
      .container {
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }

      /* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
      .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      /* Create a custom checkbox */
      .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 15px;
        width: 15px;
        border: 1px solid #ccc;
        border-radius: 50%;
      }

      /* On mouse-over, add a grey background color */
      .container:hover input ~ .checkmark {
        background-color: #030303;
      }

      /* When the checkbox is checked, add a blue background */
      .container input:checked ~ .checkmark {
        background: #ddff2b;
      }

      /* Create the checkmark/indicator (hidden when not checked) */
      .checkmark:after {
        content: "";
        position: absolute;
        display: none;
      }

      /* Show the checkmark when checked */
      .container input:checked ~ .checkmark:after {
        display: block;
      }

      /* Style the checkmark/indicator */
      .container .checkmark:after {
        left: 3px;
        top: 0px;
        width: 5px;
        height: 10px;
        border: solid #030303;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }

    .done_btn {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #030303;
      border: 0;
      font-weight: 500;
      background: #ddff2b;
      padding: 3%;
      border-radius: 10px;
      height: 16%;

      .loader_size {
        width: 25px;
        height: 25px;
      }
    }
  }

  .smaller_wrapper_none {
    display: none;
  }

  .curr_playlist {
    transform: ease-in;
    position: absolute;
    margin: auto;
    height: 250px;
    overflow: hidden;
    inset: 0;
    width: 450px;
    display: flex;
    flex-direction: column;

    border-radius: 8px;
   
    padding: 1rem;
    font-size: 16px;

    @media screen and (max-width: 615px) {
      font-size: 14px;
      width: 80%;
    }

    .close_image {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 4px;
      top: 5px;

      .close_img_sz {
        width: 100%;
        height: 100%;
      }
    }
    .cur_small_wrapper::-webkit-scrollbar {
      width: 3px;
      height: 18px;
      margin-left: 3px;
    }
    .cur_small_wrapper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 10px;
      display: flex;
      flex-direction: column;

      .create_play {
        width: 100%;
        display: flex;

        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        // margin-bottom:1.5rem;

        .create_icon_wrap {
          background: #ddff2b;
          border: 0;
          border-radius: 4px;
          height: 50px;
          width: 50px;
          position: relative;
          @media screen and (max-width: 615px) {
            height: 40px;
            width: 40px;
          }

          .create_folder_icon {
            width: 25px;
            height: 25px;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            @media screen and (max-width: 615px) {
              height: 20px;
              width: 20px;
            }

            .img_sz {
              width: 100%;
              height: 100%;
            }
          }
        }
        .create_text {
        }
      }

      .created_play {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        //margin-bottom:1.5rem;

        .created_folder_icon {
          height: 65px;
          width: 50px;
          position: relative;
          @media screen and (max-width: 615px) {
            height: 55px;
            width: 40px;
          }
          .img_sz {
            width: 100%;
            height: 100%;
          }
        }
      }
      .created_text {
      }
    }
  }

  .curr_playlist_none {
    display: none;
    //height:250px;
  }
}

.addplay_wrapper_none {
  display: none;
}

