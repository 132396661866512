.simrp_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // background-color: #202020;
  gap: 3.5rem;
  flex-wrap: wrap;
  margin-top: 3rem;

  @media screen and (max-width: 615px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-top: 1rem;
    gap: 0.5rem;
    justify-content: unset;
  }
}
