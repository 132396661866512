.playlist_wrapper {
  padding: 6% 2% 2% 2%;
  @media screen and (max-width: 615px) {
    padding: 11% 2% 2% 2%;
  }
  .play_header_link {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 10;
    background: #090909;
  }
  .playlist_filter {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    @media screen and (max-width: 600px) {
      padding-top: 4.2em;
      padding-right: 2em;
    }
    width: 100%;
    .playlist_filter_categories::-webkit-scrollbar {
      display: none;
    }
    .playlist_filter_language::-webkit-scrollbar {
      display: none;
    }
    .playlist_filter_categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      flex-wrap: wrap;

      @media screen and (max-width: 600px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 110%;
        padding-right: 8%;
      }
    }
    .playlist_filter_language {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      flex-wrap: wrap;
      margin-top: 0.8rem;
      @media screen and (max-width: 600px) {
        flex-wrap: nowrap;
        overflow-x: auto;
        width: 110%;
        padding-right: 8%;
      }
    }
  }
  .load_desktop {
    width: 100%;
    height: 300px;
    position: relative;
    .load {
      width: 100%;
      height: 50%;
      position: absolute;
      inset: 0;

      margin: auto auto;
    }
  }
  .mgt {
    margin-top: 3em;
  }

  .playlist_widget {
    //padding: 1rem 0rem 0rem;
    // overflow: hidden;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 1rem;

    //display:grid;
    //grid-template-columns:repeat(5, auto);
    //width: 100%;
    gap: 1rem;
    @media screen and (max-width: 1060px) {
      //justify-content: center;
      //display:grid;
      // grid-template-columns:repeat(4, auto);
      //gap:2rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 890px) {
      //justify-content: center;
      //display:grid;
      //grid-template-columns:repeat(4, auto);
      //gap:2rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 768px) {
      //grid-template-columns:repeat(3, auto);
      //gap:2rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 615px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
      justify-content: unset;
      //margin-top: 2rem;
    }

    .playlist_lists_items {
      cursor: pointer;
      width: 18%;
      height: 180px;
      @media screen and (max-width: 1100px) {
        width: 23%;
        //height:175px;
      }
      @media screen and (max-width: 1000px) {
        //width:140px;
        // width:20%;
        height: 160px;
      }
      @media screen and (max-width: 890px) {
        width: 20%;
        height: 180px;
      }
      @media screen and (max-width: 850px) {
        height: 160px;
      }
      @media screen and (max-width: 768px) {
        width: 22%;
        height: 180px;
      }
      @media screen and (max-width: 670px) {
        width: 23%;
        height: 180px;
      }
      @media screen and (max-width: 640px) {
        width: 31%;
        height: 180px;
      }
      @media screen and (max-width: 615px) {
        width: 100%;
        height: 165px;
      }
    }
  }
}
