.svg {
  opacity: 0.5;
}

.stroke {
  stroke: #ffff;
}
.stroke:hover {
  stroke: #ddff2b;
}
