.lecturerWidget_wrapper:hover .lecturerWidget_text {
  font-family: "Nunito Sans";
  font-style: normal;

  font-weight: 400;
  font-size: 14px;
  display: block;
  line-height: 18px;
  text-align: center;
 

  margin: unset;
}

.lecturerWidget_wrapper {
  width: 180px;
  height: 260px;
  display: flex;

  @media screen and (max-width: 615px) {
    display: none;
  }
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  //justify-content: space-between;
  @media screen and (max-width: 1238px) {
    width: 130px;
    height: 260px;
  }
  @media screen and (max-width: 1010px) {
    width: 130px;
    height: 260px;
  }
  @media screen and (max-width: 890px) {
    width: 130px;
    height: 260px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
    height: 220px;
  }

  @media screen and (max-width: 615px) {
    display: none;
  }

  .lecturerWidget_circle {
   
    width: 165px;
    height: 165px;
    margin-top: 3px;
    @media screen and (max-width: 1238px) {
      width: 120px;
      height: 120px;
    }
    @media screen and (max-width: 1010px) {
      width: 120px;
      height: 120px;
    }

    @media screen and (max-width: 890px) {
      width: 120px;
      height: 120px;
    }
    @media screen and (max-width: 768px) {
      width: 140px;
      height: 140px;
    }
    @media screen and (max-width: 650px) {
      width: 140px;
      height: 140px;
    }
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    @media screen and (max-width: 615px) {
    }
    .lecturerWidget_img {
      width: 100%;
      height: 100%;
    }
  }
  .lecturerWidget_text {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: block;

    margin: unset;
    text-align: center;
  }
  .lecturerWidget_rpname {
    font-size: 0.85rem;
    color: #a7a7a7;
    margin-top: -0.5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .lecturerWidget_views_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 0.6rem;
    .lecturerWidget_views_icon {
      font-size: 16px;
      //color: #868686;
    }
    .lecturerWidget_views_text {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      //color: #868686;
      margin: unset;
      text-shadow: 0px 3px 4px rgba(44, 44, 44, 0.25);
    }
  }
}

// ---------------responsive lec widget--------------
.lecwidres_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  gap: 1rem;

  @media screen and (min-width: 615px) {
    display: none;
  }
  .lecwidres_img_wrap {
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .lecwidres_img {
      width: 100%;
      height: 100%;
    }
  }
  .lecwidres_text_wrap {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    //width: 100%;
    .lecwidres_text {
      @media screen and (max-width: 615px) {
        font-size: 12px;
      }
      color: #aeaeae;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
    }
  }
}
