@mixin input_style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  height: 47px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5294px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  line-height: 1%;

  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.5294px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    line-height: 1%;
    color: #5e5e5e;
  }
}
@mixin lang_style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d4d4d4;
  background-color: rgba(255, 255, 255, 0);
  outline: none;
  height: 47px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5294px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  line-height: 1%;

  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.5294px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    line-height: 1%;
    color: #5e5e5e;
  }
}
@mixin dropdown_style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: white;
  outline: none;
  height: 47px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5294px;
  color: #070707;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  line-height: 1%;
}

.signupform_wrapper {
  width: 100%;
  .signupform_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .signupform_fullname {
      @include input_style;
      padding-left: 1rem;
      margin-bottom: 3%;
      @media screen and (max-width: 600px) {
        margin-bottom: 5%;
      }
    }
    .signupform_name {
      @include input_style;
      padding-left: 1rem;
      margin-bottom: 3%;
      @media screen and (max-width: 600px) {
        margin-bottom: 5%;
      }
    }
    .rbb {
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .bb {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }
    .signupform_lang {
      @include lang_style;
      padding-left: 1rem;
      margin-bottom: 3%;
      position: relative;
      @media screen and (max-width: 600px) {
        margin-bottom: 5%;
      }
      .selected_lang {
        position: absolute;
      }
      .selected_lang_none {
        position: absolute;
        color: #5e5e5e;
      }
      .selected_lang_drop {
        width: 100.3%;
        position: absolute;
        top: 46px;
        left: -1px;
        

        .drops {
          width: 100%;
          @include dropdown_style;
          padding:4% 3%;
        }
        .dw {
          background: white;
        }
        .dg {
          background: rgba(245, 245, 245, 0.8);
        }
      }
    }
    .signupform_password_wrap {
      @include input_style;
      margin-bottom: 3%;
      @media screen and (max-width: 600px) {
        margin-bottom: 5%;
      }
      .signupform_password {
        background-color: rgba(255, 255, 255, 0);
        outline: none;
        border: none;
        width: 85%;
        height: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15.5294px;
        line-height: 22px;
        padding-left: 1rem;
      
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .signupform_password_icon_show_wrap,
      .signupform_password_icon_hide_wrap {
        height: 100%;
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .signupform_password_icon_show,
        .signupform_password_icon_hide {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .signupform_confpassword_wrap {
      @include input_style;
      margin-bottom: 3%;
      .signupform_confpassword {
        background-color: rgba(255, 255, 255, 0);
        outline: none;
        border: none;
        width: 85%;
        height: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15.5294px;
        line-height: 22px;
        padding-left: 1rem;
   

        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .signupform_confpassword_icon_show_wrap,
      .signupform_confpassword_icon_hide_wrap {
        height: 100%;
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .signupform_confpassword_icon_show,
        .signupform_confpassword_icon_hide {
          font-size: 20px;
          cursor: pointer;
          @media screen and (max-width: 600px) {
            font-size: 15px;
          }
        }
      }
    }
    .signupform_gender_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 3% 0rem 3%;
      gap: 3rem;
      @media screen and (max-width: 600px) {
        margin: 5% 0rem 5%;
      }
      @media screen and (min-width: 700px) {
        justify-content: space-between;
        gap: unset;
        width: 100%;
      }

      .signupform_male {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        .signupform_male_button {
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .signupform_male_icon {
            color: black;
            font-size: 12px;
          }
        }
        .signupform_male_button_active {
          background-color: #d6ff00;
        }
        .signupform_male_button_inactive {
          background-color: #aeaeae;
        }
        .signupform_male_text {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;

          line-height: 22px;
          color: #d4d4d4;
          margin: unset;
        }
      }
      .signupform_female {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        .signupform_female_button {
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          .signupform_female_icon {
            color: black;
            font-size: 12px;
          }
        }
        .signupform_female_button_active {
          background-color: #d6ff00;
        }
        .signupform_female_button_inactive {
          background-color: #aeaeae;
        }
        .signupform_female_text {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          color: #d4d4d4;
          margin: unset;
        }
      }
    }
    .signupform_button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #d6ff00;
      height: 3.2rem;
      width: 100%;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
      line-height: 26px;
      letter-spacing: 0.279529px;
      color: #070707;
      margin-bottom: 1rem;
      border: none;
      cursor: pointer;
    }
    .signupform_terms {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      margin-bottom: 2rem;
      .signupform_terms_button {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        border: 1px solid #cfcfcf;
      }
      .signupform_terms_button_active {
        background-color: #d6ff00;
      }
      .signupform_terms_button_inactive {
        background-color: rgba(255, 255, 255, 0);
      }
      .signupform_terms_text {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.2rem;
        .signupform_terms_text1,
        .signupform_terms_text2 {
          font-family: "Nunito Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 1%;
        }
        .signupform_terms_text1 {
          
          margin: unset;
        }
        .signupform_terms_text2 {
        
          cursor: pointer;
          margin: unset;
        }
      }
    }
    .signupform_or {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;

      @media screen and (min-height: 700px) {
        margin-top: 15%;
      }
      @media screen and (min-height: 896px) {
        margin-top: 25%;
      }
      @media screen and (max-width: 600px) {
        font-size: 13px;
      }

      line-height: 1%;
    
      align-self: center;
      margin-bottom: 4%;
    }
    .signupform_social_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      width: 100%;

      @media screen and (max-width: 450px) {
        bottom: 80px;
        position: absolute;
        left: 0;
        right: 0;
      }
      * {
        cursor: pointer;
      }
    }
    .zindex {
      z-index: -1;
      @media screen and (max-width: 450px) {
        z-index: 0;
      }
    }
  }
}
