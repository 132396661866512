.video_wrapper {
  padding: 4% 2% 4% 2%;
  @media screen and (max-width: 600px) {
    padding: 13% 2% 4% 2%;
  }
  .vid_header_link {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 10;
  }
  .video_filter {
    @media screen and (max-width: 615px) {
      padding-top: 4.7rem;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    overflow: hidden;
    .video_filter_categories::-webkit-scrollbar {
      display: none;
    }
    .video_filter_categories {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      flex-wrap: nowrap;
      width: 110%;
      padding-right: 15%;
      overflow-x: scroll;
      scroll-behavior: smooth;
    }
    .video_filter_language {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.8rem;
      flex-wrap: wrap;
      margin-top: 0.8rem;
    }
  }
  .video_widget {
    padding: 2rem 0rem 0rem;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    width: 100%;
    gap: 1rem;
    justify-content: center;
    @media screen and (max-width: 1238px) {
      //display:grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 2rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 980px) {
      gap: 1rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 10px) {
      //justify-content: center;
      //display:grid;
      //grid-template-columns:repeat(4, auto);
      //gap:1rem;
      //margin-top: 2rem;
    }

    @media screen and (max-width: 890px) {
      justify-content: center;
      //display:grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1rem;
      //margin-top: 2rem;
    }
    @media screen and (max-width: 777px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
      //margin-top: 2rem;
    }

    @media screen and (max-width: 615px) {
      padding: 1rem 0rem;
      justify-content: center;
      display: grid;
      grid-template-columns: repeat(1, minmax(0, 1fr));
      gap: 1rem;
      //margin-top: 4rem;
    }
  }
}
