@mixin title_text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--text-color);
}

.more_wrapper {
  min-height: 100vh;
  padding-bottom: 24px;
  
  .more_widget {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 24px;
    padding: 24px;
    
    .widget_list_items {
      width: 100%;
      cursor: pointer;
      text-decoration: none;
      background: hsl(var(--background));
      border-radius: 8px;
      
      .widget_img_wrap {
        width: 100%;
        aspect-ratio: 1;
        border-radius: 8px;
        overflow: hidden;
        position: relative;
        
        .widget_img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        
        .widget_views {
          position: absolute;
          top: 8px;
          right: 8px;
          display: flex;
          align-items: center;
          gap: 4px;
          padding: 2px 8px;
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          color: white;
          font-size: 12px;
          
          .widget_views_icon {
            font-size: 14px;
          }
        }
      }
      
      .widget_text {
        padding: 12px 8px;
        
        .widget_title {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.4;
          margin-bottom: 4px;
          color: hsl(var(--foreground));
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        
        .widget_lecturer {
          font-size: 13px;
          color: hsl(var(--muted-foreground));
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
  
  // Mobile adjustments
  @media (max-width: 768px) {
    .more_widget {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 16px;
      padding: 16px;
      
      .widget_list_items {
        .widget_img_wrap {
          .widget_views {
            padding: 2px 6px;
            font-size: 11px;
            
            .widget_views_icon {
              font-size: 12px;
            }
          }
        }
        
        .widget_text {
          padding: 8px 4px;
          
          .widget_title {
            font-size: 13px;
          }
          
          .widget_lecturer {
            font-size: 12px;
          }
        }
      }
    }
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInItem {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.fade-in-item {
  opacity: 0;
  animation: fadeInItem 0.5s ease-out forwards;
}

// Responsive adjustments
@media (max-width: 640px) {
  .more_wrapper {
    .more_widget:not(.list-view) {
      @apply grid-cols-2 gap-3 p-3;
    }

    .widget_list_items {
      &:hover {
        transform: translateY(-2px);
      }
    }
  }
}

// Dark mode adjustments
@media (prefers-color-scheme: dark) {
  .more_wrapper {
    .widget_list_items {
      @apply bg-background border border-border;
      
      &:hover {
        @apply border-border/80 shadow-lg shadow-accent/5;
      }
    }
  }
}

// Reduced motion preferences
@media (prefers-reduced-motion: reduce) {
  .fade-in,
  .fade-in-item {
    animation: none;
    opacity: 1;
  }

  .widget_list_items {
    &:hover {
      transform: none;
    }
  }

  .filter-panel > div {
    animation: none;
  }
}
