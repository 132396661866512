// ---------------responsive lec widget--------------
.lecwidres_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  gap: 1rem;

  @media screen and (min-width: 615px) {
    display: none;
  }
  .lecwidres_img_wrap {
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    .lecwidres_img {
      width: 100%;
      height: 100%;
    }
  }
  .lecwidres_text_wrap {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    //width: 100%;
    .lecwidres_text {
      @media screen and (max-width: 615px) {
        font-size: 12px;
      }
      color: #aeaeae;
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 200px;
      overflow: hidden;
    }
  }
}
