.genre_wrapper {
  padding: 4% 2% 2% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding: 13% 2% 2% 2%;
  }
  .genre_header_link {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 10;
    
  }
  .load_x {
    width: 100%;
    height: 300px;
    @media screen and (max-width: 615px) {
      height: 400px;
      margin-top: 4em;
    }
    position: relative;
    .load_y {
      width: 100%;
      height: 50%;
      position: absolute;
      inset: 0;

      margin: auto auto;
    }
  }
  .genre_lists {
    @media screen and (max-width: 615px) {
      margin-top: 4em;
    }
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, auto);
    // display: flex;
    //flex-direction: row;
    //align-items: center;
    justify-content: center;
    //flex-wrap: wrap;
    gap: 1rem;
    @media screen and (max-width: 1100px) {
      grid-template-columns: repeat(4, auto);
      gap: 1rem;
    }
    @media screen and (max-width: 890px) {
      grid-template-columns: repeat(5, auto);
      gap: 0.5rem;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(4, auto);
      gap: 1rem;
    }
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(3, auto);
      gap: 1.5rem;
    }
    @media screen and (max-width: 615px) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      // grid-template-columns: repeat(2, auto);
      gap: 1rem;
      //justify-content: unset;
    }
  }
  .genre_img_wrap {
    border-radius: 6px;
    width: 180px;
    height: 180px;
    @media screen and (max-width: 1238px) {
      height: 160px;
      width: 150px;
    }
    @media screen and (max-width: 1100px) {
      height: 170px;
      width: 170px;
    }
    @media screen and (max-width: 1000px) {
      height: 160px;
      width: 150px;
    }
    @media screen and (max-width: 890px) {
      height: 160px;
    }
    @media screen and (max-width: 700px) {
      height: 180px;
      width: 180px;
    }
    @media screen and (max-width: 615px) {
      height: 160px;
      width: 45vw;

      border-radius: 3px;
    }

    //overflow: hidden;
    cursor: pointer;
    position: relative;

    .genre_img {
      width: 100%;
      height: 100%;
      @media screen and (max-width: 615px) {
        border-radius: 3px;
      }
      border-radius: 6px;
    }
    .name_abs {
      font-family: "Poppins";
      font-size: 15px;
      color: white;
      font-weight: 500;
      position: absolute;
      left: 7px;
      bottom: 3px;
      z-index: 1;
      @media screen and (max-width: 615px) {
        font-size: 14px;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      @media screen and (max-width: 615px) {
        border-radius: 3px;
      }
      background: linear-gradient(
        180deg,
        rgba(217, 217, 217, 0) 0%,
        rgba(25, 25, 26, 0.88) 71.74%
      );
    }
  }
}
