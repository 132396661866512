@mixin mobile_hide {
  @media screen and (max-width: 615px) {
    display: none;
  }
}
.load_desktop {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media screen and (max-width: 615px) {
    height: 150px;
  }
}
.lecalb_wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
  padding: 24px 0;
  width: 100%;

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  @media screen and (max-width: 615px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    padding: 16px 0;
  }

  .lecalb_album_item {
    cursor: pointer;
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease;
    background: var(--background);
    
    &:hover {
      transform: translateY(-4px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      background: linear-gradient(180deg, transparent 50%, rgba(0, 0, 0, 0.8));
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover::after {
      opacity: 1;
    }
  }
}
.lecalb_comments {
  margin-top: 32px;
  padding-top: 24px;
  border-top: 1px solid var(--border);
}
