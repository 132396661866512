@mixin LinkHover {
  background: #02aae2;
  border-radius: 10px;
  // color: white;
}
// .icontext_active {
//   @include LinkHover();
//   margin-bottom: 0.5rem;
//   margin-top: 0.5rem;
// }

.icontext_link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  .icontext_icon {
    margin-top: 3px;
  }
  &:hover {
    .icontext_icon {
      .icon {
       // color: #d6ff00;
      }
    }
    .icontext_name {
     // color: #d6ff00;
    }
  }
  .icontext_icon {
    .icon {
    //  color: #aeaeae;
      font-size: 16px;
      margin-bottom: 1rem;
    }
  }
  .icontext_name {
    margin-left: 1rem;
    font-family: "Nunito Sans";
    font-style: normal;
    
    margin-bottom: 1rem;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
  //  color: #868686;
  }
}
