@mixin flex_row {
  display: flex;
  flex-direction: row;
}
@mixin flex_column {
  display: flex;
  flex-direction: column;
}

.container_wrapper {
  @include flex_column;
  width: 100%;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  .container_child {
    width: 100%;
    padding: 3.5rem 0px;

    //overflow: hidden;
    @media screen and (max-width: 690px) {
      padding-top: 3rem;
    }
  }
}
