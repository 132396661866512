@mixin input_style {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid #d4d4d4;
  border-radius: 5px;
  background-color: #ffffff00;
  outline: none;
  height: 47px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15.5294px;
  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
  line-height: 22px;

  &::placeholder {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 15.5294px;
    @media screen and (max-width: 600px) {
      font-size: 14px;
    }
    line-height: 22px;
    color: #5e5e5e;
  }
}

.loginform_wrapper {
  width: 100%;
  //overflow-y: auto;

  .loginform_form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    .loginform_name {
      @include input_style;
      padding-left: 1rem;
      margin-bottom: 1.5rem;
    }
    .loginform_password_wrap {
      @include input_style;
      .loginform_password {
        background-color: rgba(255, 255, 255, 0);
        outline: none;
        border: none;
        width: 85%;
        height: 100%;
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 15.5294px;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
        line-height: 22px;
        padding-left: 1rem;
      
      }
      .loginform_password_icon_show_wrap,
      .loginform_password_icon_hide_wrap {
        height: 100%;
        width: 15%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .loginform_password_icon_show,
        .loginform_password_icon_hide {
          font-size: 20px;
          cursor: pointer;
        }
      }
    }
    .loginform_forgot_wrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin: 1.5rem 0rem 3rem;
      cursor: pointer;
      .loginform_forgot {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #8faa00;
        margin: unset;
        padding: unset;
      }
    }
    .loginform_button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #d6ff00;
      height: 3.2rem;
      width: 100%;
      border-radius: 5px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      @media screen and (max-width: 600px) {
        font-size: 15px;
      }
      line-height: 26px;
      letter-spacing: 0.279529px;
      color: #070707;
      margin-bottom: 10%;
      border: none;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        margin-bottom: 25%;
      }
    }
    .loginform_or {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 17px;

      line-height: 23px;
    
      align-self: center;
      margin-bottom: 10%;
      @media screen and (min-height: 700px) {
        margin-top: 18%;
      }
      @media screen and (min-height: 896px) {
        margin-top: 28%;
      }
      @media screen and (max-width: 600px) {
        font-size: 13px;
      }
    }
  }
  .loginform_social_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    @media screen and (max-width: 600px) {
      bottom: 80px;
    }
    * {
      cursor: pointer;
    }
  }

  .show {
    display: block;
  }
}
