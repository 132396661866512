.comment-box {
  //width:inheri;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem 0 2rem;
  width: 70%;
  @media screen and (max-width: 1096px) {
    width: 100%;
  }
  .lecalb_comments_header {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    @media screen and (max-width: 615px) {
      font-size: 15px;
    }
    line-height: 42px;
    margin: unset;
  }
  .lecalb_comment_input {
    border: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    resize: none;
    color: #d4d4d4;

    &::placeholder {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
    }
  }
  .lecalb_comment_action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    .lecalb_comment_moji {
      color: #d4d4d4;
      font-size: 20px;
      cursor: pointer;
    }
    .lecalb_comment_button {
      background: #ddff2b;
      border-radius: 12px;
      border: none;
      width: 108px;
      height: 29px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      cursor: pointer;
    }
  }

  .aud_comment_texts {
    margin-top: 1rem;
    width: 100%;
    font-family: "Poppins";
    .com_wrap {
      padding: 3% 2%;
      border-bottom: 1px solid #353535;
      width: 100%;
      .com_date {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1rem;
        .logo_img {
          width: 18px;
          height: 18px;
          @media screen and (max-width: 615px) {
            width: 16px;
            height: 16px;
          }

          .logo_img_sz {
            width: 100%;
            height: 100%;
          }
        }

        .commentor {
          color: #868686;
          text-transform: capitalize;
          font-size: 14px;

          @media screen and (max-width: 615px) {
            font-size: 12px;
          }
        }
        .comment_date {
          font-size: 12px;
          color: #5e5e5e;
          @media screen and (max-width: 615px) {
            font-size: 10px;
          }
        }
      }
      .comment_content {
        color: #d4d4d4;
        font-size: 14px;
        padding-left: 2rem;

        @media screen and (max-width: 615px) {
          font-size: 12px;
        }
      }
    }
  }
}
