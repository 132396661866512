.filter_wrapper {
  //overflow: hidden;
  cursor: pointer;
  height: 30px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background: #5e5e5e;
  padding: 0rem 0.9rem;
  //padding-right: 1rem;
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  @media screen and (max-width: 600px) {
    font-size: 12px;
  }
  //line-height: 24px;
  color: #ffffff;
  &:hover {
    background-color: #ddff2b;
    color: #000000;
  }
  .filter_text {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    @media screen and (max-width: 400px) {
      max-width: 200px;
    }
  }
}
.filter_active {
  background-color: #ddff2b;
  color: #000000;
}
