@mixin mobile_hide {
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.load_desktop {
  width: 100%;
  height: 400px;
  position: relative;
  @include mobile_hide;
  .load {
    width: 100%;
    height: 50%;
    position: absolute;
    inset: 0;

    margin: auto auto;
  }
}

.lecplaylist_wrapper {
  padding: 1rem 0rem 0rem;
  // overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;

  //display:grid;
  //grid-template-columns:repeat(5, auto);
  //width: 100%;
  gap: 1rem;
  @media screen and (max-width: 1060px) {
    //justify-content: center;
    //display:grid;
    // grid-template-columns:repeat(4, auto);
    //gap:2rem;
    //margin-top: 2rem;
  }
  @media screen and (max-width: 890px) {
    //justify-content: center;
    //display:grid;
    //grid-template-columns:repeat(4, auto);
    //gap:2rem;
    //margin-top: 2rem;
  }
  @media screen and (max-width: 768px) {
    //grid-template-columns:repeat(3, auto);
    //gap:2rem;
    //margin-top: 2rem;
  }
  @media screen and (max-width: 615px) {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 1rem;
    //margin-top: 2rem;
  }

  //gap: 1rem;
  .lecplaylist_item {
    cursor: pointer;
    width: 18%;
    height: 220px;
    @media screen and (max-width: 1100px) {
      width: 23%;
      //height:175px;
    }
    @media screen and (max-width: 1000px) {
      //width:140px;
      // width:20%;
      height: 200px;
    }
    @media screen and (max-width: 890px) {
      width: 20%;
      height: 220px;
    }
    @media screen and (max-width: 850px) {
      height: 200px;
    }
    @media screen and (max-width: 768px) {
      width: 22%;
      height: 210px;
    }
    @media screen and (max-width: 670px) {
      width: 23%;
      height: 180px;
    }
    @media screen and (max-width: 640px) {
      width: 31%;
      height: 200px;
    }
    @media screen and (max-width: 615px) {
      width: 100%;
      height: 170px;
    }
  }
}
.lecplaylist_comments {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  margin: 1rem 0 1rem;
  width: 70%;
  @media screen and (max-width: 1096px) {
    width: 100%;
  }
  .lecplaylist_comments_header {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    @media screen and (max-width: 615px) {
      font-size: 15px;
    }
    line-height: 42px;
    color: #d4d4d4;
    margin: unset;
  }
  .lecplaylist_comment_input {
    background: #222222;
    border: 1px solid rgba(212, 212, 212, 0.5);
    border-radius: 4px;
    width: 100%;
    padding: 1rem;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #d4d4d4;

    &::placeholder {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #999999;
    }
  }
  .lecplaylist_comment_action {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    align-self: flex-end;
    .lecplaylist_comment_moji {
      color: #d4d4d4;
      font-size: 20px;
      cursor: pointer;
    }
    .lecplaylist_comment_button {
      background: #ddff2b;
      border-radius: 12px;
      border: none;
      width: 108px;
      height: 29px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      cursor: pointer;
    }
  }
}
