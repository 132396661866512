.visible {
  visibility: hidden;
}

.active {
  @apply z-[10] scale-[1] opacity-100;
  visibility: visible;
}

.preactive {
  @apply scale-[0.9] z-[7] translate-x-[22%] opacity-100;
  filter: brightness(30%);
  visibility: visible;
}

.third {
  @apply translate-x-[45%] scale-[0.8] opacity-100;
  filter: brightness(50%);
  visibility: visible;
}
