.landop_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  .landop_widget {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    border-radius: 50%;
    background: #ddff2b;
    overflow: hidden;
    cursor: pointer;

    color: #0d0d0d;
    font-size: 22px;
    .landop_pics {
      width: 22px;
      height: 22px;
    }
  }
  .landop_text {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
   
    margin: unset;
  }
}
