.app_wrapper {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

* {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

::-webkit-scrollbar {
  width: 10px;
  height: 18px;
}
::-moz-scrollbar {
  width: 10px;
  height: 18px;
}

@media screen and (max-width: 615px) {
  ::-webkit-scrollbar {
    display: none;
  }
  ::-moz-scrollbar {
    display: none;
  }
}

.let {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.swipeDown {
  animation-name: swipeDown;
  -webkit-animation-name: swipeDown;
}

@keyframes swipeDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0px);
  }
}

@-webkit-keyframes swipeDown {
  0% {
    -webkit-transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

.swipeUp {
  animation-name: swipep;
  -webkit-animation-name: swipep;
}

@keyframes swipep {
  from {
    bottom: -130px;
  }

  to {
    bottom: 130px;
  }
}

@-webkit-keyframes swipep {
  0% {
    -webkit-transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(0px);
  }
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(244, 245, 234, 0.5);
}

::-moz-scrollbar-thumb {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
}

::-moz-scrollbar-track {
  border-radius: 8px;
  background-color: rgba(244, 245, 234, 0.5);
}

.gradientbg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background: linear-gradient(
    180deg,
    rgba(217, 217, 217, 0) 0%,
    rgba(25, 25, 26, 0.88) 71.74%
  );
}
.gradientgenre {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(13, 13, 13, 1) 61.74%
  );
}
.audioCommentBoxWrap {
  width:80%;
  padding-left:20px;
}
.gradientgenre_light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 1) 61.74%
  );
}
@media screen and (max-width: 615px) {
  .gradientgenre {
    display: none;
  }
}
.boom {
  font-family: "Nunito Sans";
}

.name_abs {
  font-family: "Poppins";
}

.brightness {
  filter: brightness(20%);
}

/* The container */
.filter-container {
  position: relative;
  padding-left: 30px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox rounded-full w-3 h-3 bg-black*/
.filter-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.filter-checkmark {
  position: absolute;
  top: 0;
  left: 0;

  height: 20px;
  width: 20px;
  border-radius: 6px;
  border: 1px solid gray;
  @apply bg-gray-300;
}
.filter-checkmark::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 10px;
  left: 6px;
  top: 3px;
  transform: rotate(50deg);
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

/* On mouse-over, add a grey background color */
.filter-container:hover input ~ .checkmark {
  background-color: white;
}

/* When the checkbox is checked, add a blue background */
.filter-container input:checked ~ .filter-checkmark {
  @apply bg-[#ddff2b];
}

/* Create the checkmark/indicator (hidden when not checked) */
.filter-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.filter-container input:checked ~ .filter-checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.filter-container .filter-checkmark:after {
  position: absolute;
  content: "";
  width: 5px;
  height: 10px;
  left: 6px;
  top: 3px;
  transform: rotate(50deg);
  @apply border-r-2 border-b-2 border-black;
  border-radius: 6px;
}

.no-range::-webkit-inner-spin-button,
.no-range::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.no-range {
  -moz-appearance: textfield;
  appearance: textfield;
}

.no-range[type="number"] {
  -moz-appearance: textfield;
}

.no-range[type="number"]::-webkit-inner-spin-button,
.no-range[type="number"]::-webkit-outer-spin-button {
  display: none;
}

@media screen and (min-width: 615px) {
  .text-color {
    color: #070707;
  }
}

.shadow {
  text-shadow: 0px 3px 4px rgba(44, 44, 44, 0.25);
}
.gradientauth {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(13, 13, 13, 1) 20%
  );
}

.gradientauth_light {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 255, 1) 20%
  );
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 11% 11%;

    --muted: 240 0% 45%;
    --muted-foreground: 0 0% 3%;


    --color: 240 10% 36%;
    --color-foreground: 240 11% 11%;
    --color-primary: 0 0% 1%;

    --actions:32 100% 100%;
    --border: 216 5% 86%;
    --input: 214.3 31.8% 91.4%;
    --hover: 220 3% 92%;
    --search: 0 0% 100%;
    --comment: 0 0% 96%;
    --auth: 0 0% 98%;

    --primary: 0 0% 100%;
    --primary-foreground: 0 0% 100%;

    --secondary: 0 0% 98%;
    --secondary-foreground:  0 0% 10%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
    --footer: 32 0% 82%;
  }

  .dark {
    --background: 0 0% 1%;
    --foreground: 0 0% 100%;

    --muted: 240 0% 83%;
    --muted-foreground: 240 0% 83%;


    --color: 220 0% 53%;
    --color-foreground: 68 100% 100%;
    --color-primary: 0 0% 88%;

    --search: 32 0% 4%;    
    --actions:32 100% 100%;
    --border:  216 0% 64%;
    --input: 32 0% 13%;
    --hover: 220 0% 13%;
    --comment: 0 0% 13%;
    --auth: 0 0% 21%;

    --primary: 32 0% 13%;
    --primary-foreground: 32 0% 5%;

    --secondary: 0 0% 5%;
    --secondary-foreground: 0 0% 80%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;

    --ring: 217.2 32.6% 17.5%;
    --footer: 32 0% 13%;
  }
}


@tailwind base;
@tailwind components;
@tailwind utilities;
