.sound_wrapper {
  height: 20px;
  @media screen and (max-width: 615px) {
    height: 15px;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1px;
}
.sound_wrapper .stroke {
  height: 100%;
  width: 2px;
  
  animation: animate 1.2s infinite linear;
}

@keyframes animate {
  50% {
    height: 20%;
  }
  100% {
    height: 100%;
  }
}

.stroke:nth-child(1) {
  animation-delay: 0s;
}
.stroke:nth-child(2) {
  animation-delay: 0.3s;
}
.stroke:nth-child(3) {
  animation-delay: 0.6s;
}
.stroke:nth-child(4) {
  animation-delay: 0.4s;
}
.stroke:nth-child(5) {
  animation-delay: 0s;
}
