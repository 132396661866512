.charts_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 4% 2% 4% 2%;
  @media screen and (max-width: 600px) {
    padding: 13% 2% 4% 2%;
  }
  .charts_header_route {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 10;
    background: #090909;
  }
  .charts_space {
    margin-bottom: 1rem;
  }
  .charts_quran {
    margin-top: 1rem;
  }
}
