.range_progress {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2px;
  z-index: 10;
}
.audiodet_bar {
  position: absolute;
  top: 0;
  height: 2px;
  z-index: 12;
  left: 0;
  background-color: #ddff2b;
}

input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  outline: none;
  background-color: gray;
  border-radius: 100px;
  height: 2px;
}
input[type="range"]::-webkit-slider-runnable-track {
  height: 2px;
}
input[type="range"]::-moz-track {
  height: 2px;
}
input[type="range"]::-ms-track {
  height: 2px;
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-top: -2px;
  background: #ddff2b;
  cursor: pointer;
  border: none;
}
input[type="range"]::-moz-range-thumb {
  -moz-appearance: none;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-top: -2px;

  background: #ddff2b;
  cursor: pointer;
  border: none;
}
input[type="range"]::-ms-range-thumb {
  appearance: none;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin-top: -2px;

  background: #ddff2b;
  cursor: pointer;
  border: none;
}

.visibles {
  visibility: 1;
}
.notvisibles {
  visibility: 0;
  display: none;
}

.transit {
  transform: translateY(0);
  transition: all 0.3s;
}

.notransit {
  transition: none;
}
