@mixin header_link_style {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  // line-height: 42px;
  //color: #cfcfcf80;
}
@mixin header_link_style_active {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  // line-height: 68px;
  //color: #f2f2f2;
}

.auth_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

 
  width: 100%;
  height: 100%;
  .auth_container {
    overflow: hidden;
    //overflow: scroll;
    padding: 1rem 1.6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    height: 100vh;
    position: relative;
    @media screen and (min-width: 690px) {
      width: 650px;
      overflow: unset;
      
    }
    .auth_hero_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 0;
      @media screen and (min-width: 690px) {
        display: none;
      }
    }
    .auth_header_logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      z-index: 1;
      * {
        cursor: pointer;
      }
    }
    .auth_header_logo_none {
      display: none;
    }
    .sl_wrapper::-webkit-scrollbar {
      width: 3px;
      height: 18px;
      margin-left: 3px;
    }
    .sl_wrapper::-moz-scrollbar {
      width: 3px;
      height: 18px;
      margin-left: 3px;
    }
    .sl_wrapper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      z-index: 10;
      @media screen and (max-width: 450px) {
        overflow-y: hidden;
        height: 100%;
        z-index: 10;
      }
      .auth_links_none {
        display: none;
      }
      .auth_links {
        display: flex;
        width: 98%;
        @media screen and (max-width: 450px) {
          width: 100%;
        }
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2rem;
        z-index: 1;
        margin-top: 1rem;
        margin-bottom: 1rem;
        height: 2.5rem;
        a {
          text-decoration: none;
          .auth_login_link {
            @include header_link_style;
            transition: font-size 0.4s ease-in-out;
            margin: unset;
            padding: unset;
          }
          .auth_login_link_active {
            @include header_link_style_active;
            transition: font-size 0.4s ease-in-out;
            margin: unset;
            padding: unset;
          }
          .auth_signup_link {
            @include header_link_style;
            transition: font-size 0.4s ease-in-out;
            margin: unset;
            padding: unset;
          }
          .auth_signup_link_active {
            @include header_link_style_active;
            transition: font-size 0.4s ease-in-out;
            margin: unset;
            padding: unset;
          }
        }
      }
      .auth_outlet {
        width: 98%;
        @media screen and (max-width: 450px) {
          width: 100%;
        }
        z-index: 2;
        margin-top: 1rem;
      }
    }
  }
}
