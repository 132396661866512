.mobilelist_wrapper {
  width: 100%;
  height: 100%;
  padding: 2%;
  font-size: 14px;
 
  font-family: "Poppins";
  position: relative;

  .mobiletd {
    width: 100%;

    .lecture {
      margin-bottom: 3px;
    }
    .lecturer {
     
      font-size: 12px;
    }
  }
}
