@mixin title_text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #5e5e5e;
}
@mixin mobile_hide {
  @media screen and (max-width: 690px) {
    display: none;
  }
}
@mixin desktop_hide {
  @media screen and (min-width: 690px) {
    display: none;
  }
}
.trend_wrapper {
  padding: 4% 2% 4% 2%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 600px) {
    padding: 13% 2% 4% 2%;
  }
  .trend_header_link {
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 10;
  
  }
  .trend_title_res_wrap {
    @media screen and (min-width: 1221px) {
      display: none;
    }
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;

    margin-bottom: 2rem;
    justify-content: flex-start;

  }
  .trend_title_wrap {
    @include mobile_hide;
    display: flex;
    //gap: 1rem;
    // grid-template-columns: repeat(11, minmax(0, 1fr));
    justify-items: flex-start;
    width: 100%;
    margin-bottom: 2rem;
    .tend_title2,
    .tend_title3,
    .tend_title4 {
    
      margin: unset;
      padding: unset;
    }
    .tend_title1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 45%;
      // grid-column: span 5 / span 5;
      * {
        @include title_text;
        margin: unset;
        padding: unset;
      }
      .tend_hash {
        margin-left: 8px;
        width: 30px;
      }
    }
    .tend_title2 {
      /// grid-column: span 2 / span 2;
      width: 40%;
      display: flex;
      align-items: start;
      justify-content: flex-start;
    }

    .tend_title4 {
      // grid-column: span 2 / span 2;
      display: flex;
      align-items: start;
      justify-content: flex-start;
    }
  }
  .load_desktop {
    width: 100%;
    height: 400px;
    position: relative;
    @include mobile_hide;
    .load {
      width: 100%;
      height: 50%;
      position: absolute;
      inset: 0;

      margin: auto auto;
    }
  }
  .table {
    @include mobile_hide;
    
    font-family: "Poppins";
    width: 100%;
    font-size: 14px;
    border-collapse: collapse;
  }

  /*************** moobile **********/

  .mobile_lists {
    @include desktop_hide;
   
 
    @media screen and (max-width:690px) {
      margin-top: 0rem;
    }
    @media screen and (max-width:600px) {
      margin-top: 4rem;
    }
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    width: 100%;
    font-family: "Poppins";

    font-size: 12px;

    .header {
      display: flex;
      gap: 1em;
      align-items: center;
      .img_size {
        border: 2px solid #cfcfcf;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        .img_size_sm {
          width: 100%;
          height: 100%;
        }
      }
      .header_text {
        font-size: 14px;
        font-weight: 500;
      }
    }
    .load_mobile {
      width: 100%;
      height: 400px;
      position: relative;
      @include desktop_hide;

      .loads {
        width: 100%;
        height: 50%;
        position: absolute;
        inset: 0;
        @include desktop_hide;
        margin: auto auto;
      }
    }
    .each_mobile_list {
      width: 100%;
      //max-height:100px;
      margin-bottom: 3px;
    }
  }

  .load_m {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

/*
   .likeys {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  gap:6px;
                  width:100%;
                  .likeys_img {
                    width:15px;
                    height:15px;
                   .likeys_img_sz {
                    width:15px;
                    height:15px;
                  }
                  }
                }
#table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.td, .th {
  border-bottom: 1px solid #dddddd;
  text-align: left;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left:8px;
}
*/
