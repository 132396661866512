.landing_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
 

  .carousel {
    width: 100%;
    //height:40%;
    @media screen and (max-width: 615px) {
      display: none;
    }
  }

  .landing_carousel {
    width: 96vw;
    height: 162px;
    //border-radius:3px ;
    @media screen and (min-width: 615px) {
      display: none;
    }
    margin-top: 42px;
    margin-bottom: 8px;
    .landing_carousel_img {
      width: 100%;
      border-radius: 3px;
      height: 162px;
    }
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
    .slick-dots {
      position: absolute;
      bottom: 11px;
      li {
        margin: 0px 0px;
        width: 16px;
        height: 16px;
      }
      li button:before {
        color: white;
      }
    }
  }
  .landing_options {
    padding: 0rem 0.1rem 0rem 0.1rem;

    //margin-top:1.8rem;
    .slick-prev:before,
    .slick-next:before {
      display: none;
    }
    @media screen and (min-width: 615px) {
      display: none;
    }
  }
}
