@mixin title_text {
  font-family: "Nunito Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #5e5e5e;
}
@mixin mobile_hide {
  @media screen and (max-width: 615px) {
    display: none;
  }
}

.lecsong_wrapper {
  padding: 1rem 0rem 3rem;
  .lecsong_title_res_wrap {
    @media screen and (min-width: 1221px) {
      display: none;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2rem;
    .lecsong_title_res_icon {
      font-size: 40px;
      color: #d4d4d4;
      cursor: pointer;
    }
    .lecsong_title_res_text {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #d4d4d4;
      margin-left: 40%;
    }
  }
  .lect_title_wrap {
    @media screen and (max-width: 690px) {
      display: none;
    }
    display: flex;
    //gap: 1rem;
    // grid-template-columns: repeat(11, minmax(0, 1fr));
    justify-items: flex-start;
    width: 100%;
    margin-bottom: 2rem;
    .lect_title2,
    .lect_title3,
    .lect_title4 {
      @include title_text;
      margin: unset;
      padding: unset;
    }
    .lect_title1 {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 45%;
      // grid-column: span 5 / span 5;
      * {
        @include title_text;
        margin: unset;
        padding: unset;
      }
      .lect_hash {
        // margin-right: 1.6rem;
        width: 30px;
      }
    }
    .lect_title2 {
      /// grid-column: span 2 / span 2;
      width: 40%;
      display: flex;
      align-items: start;
      justify-content: flex-start;
    }

    .lect_title4 {
      // grid-column: span 2 / span 2;
      display: flex;
      align-items: start;
      justify-content: flex-start;
    }
  }
  .load_desktop {
    width: 100%;
    height: 400px;
    position: relative;
    @include mobile_hide;
    .load {
      width: 100%;
      height: 50%;
      position: absolute;
      inset: 0;

      margin: auto auto;
    }
  }
  .lecsong_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    .lecsong_content_item {
      cursor: pointer;
      .desktops_item {
        @media screen and (max-width: 615px) {
          display: none;
        }
      }
      .mobile_item {
        font-size: 14px;
        color: white;
        width: 100%;
        font-family: "Poppins";
        padding: 2%;
        border-bottom: 1px solid #999999;
        margin-bottom: 3px;
        @media screen and (min-width: 615px) {
          display: none;
        }
        .lecture {
          margin-bottom: 3px;
        }
        .lecturer {
          color: #868686;
          font-size: 12px;
        }
      }
    }
  }

  .lecsong_comments {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
    margin: 1rem 0 1rem;
    width: 70%;
    @media screen and (max-width: 1096px) {
      width: 100%;
    }
    .lecsong_comments_header {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      @media screen and (max-width: 615px) {
        font-size: 15px;
      }
      line-height: 42px;
      color: #d4d4d4;
      margin: unset;
    }
    .lecsong_comment_input {
      background: #222222;
      border: 1px solid rgba(212, 212, 212, 0.5);
      border-radius: 4px;
      width: 100%;
      padding: 1rem;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      resize: none;
      font-size: 16px;
      line-height: 21px;
      color: #d4d4d4;

      &::placeholder {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #999999;
      }
    }
    .lecsong_comment_action {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
      align-self: flex-end;
      .lecsong_comment_moji {
        color: #d4d4d4;
        font-size: 20px;
        cursor: pointer;
      }
      .lecsong_comment_button {
        background: #ddff2b;
        border-radius: 12px;
        border: none;
        width: 108px;
        height: 29px;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #000000;
        cursor: pointer;
      }
    }

    .aud_comment_texts {
      margin-top: 1rem;
      width: 100%;
      font-family: "Poppins";
      .com_wrap {
        padding: 3% 2%;
        border-bottom: 1px solid #353535;
        width: 100%;
        .com_date {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 1rem;
          .logo_img {
            width: 18px;
            height: 18px;
            @media screen and (max-width: 615px) {
              width: 16px;
              height: 16px;
            }

            .logo_img_sz {
              width: 100%;
              height: 100%;
            }
          }

          .commentor {
            color: #868686;
            text-transform: capitalize;
            font-size: 14px;

            @media screen and (max-width: 615px) {
              font-size: 12px;
            }
          }
          .comment_date {
            font-size: 12px;
            color: #5e5e5e;
            @media screen and (max-width: 615px) {
              font-size: 10px;
            }
          }
        }
        .comment_content {
          color: #d4d4d4;
          font-size: 14px;
          padding-left: 2rem;

          @media screen and (max-width: 615px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
