.search_wrapper {
  border-radius: 20px;
  width: 350px;
  position: relative;
  padding: 3px 10px 3px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  z-index: 50;
  
  .search_icon {
    color: rgba(212, 212, 212, 1);
    font-size: 16px;
    margin-right: 1rem;
    cursor: pointer;
  }
  
  .search_input {
    background-color: unset;
    border: none;
    outline: none;
    width: 90%;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
  
    &::placeholder {
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
    }
  }
}

.search_dropdown {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  background: white;
  z-index: 40;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 8px;
  
  img {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
  }
}
