@mixin nav_alignment {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;

  //width: 100%;
  z-index: 15;
  // background:#030303;
  height: fit-content;
  padding: 1%;
  @media screen and (max-width: 615px) {
    padding: 2%;
    width: 100%;
    right: 0;
  }
}

.nav_container {
  @include nav_alignment;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  
  mix-blend-mode: normal;

  //border: 1px solid rgba(205, 205, 205, 0.1);
  .nav_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .nav_logo {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      align-items: center;
      .nav_res_hamburger {
        display: none;
        cursor: pointer;
        @media screen and (max-width: 890px) {
          display: block;
        
          font-size: 2rem;
        }
      }
      .nav_logo {
        //display: none;
        width: 35px;
        height: 35px;
        @media screen and (max-width: 890px) {
          display: block;
          width: 45%;
          height: 40px;
        }
        .logo_img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .hide {
      width: 100px;
      height: 1px;
      background: none;
      @media screen and (max-width: 615px) {
        display: none;
      }
    }
    .nav_search {
      @media screen and (max-width: 1273px) {
      }
      @media screen and (max-width: 615px) {
        display: none;
      }
    }
    .nav_download {
      display: flex;
      position: absolute;
      right: 10px;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      @media screen and (max-width: 1273px) {
      }

      @media screen and (max-width: 1035px) {
        display: none;
      }
    }
    .nav_res_download_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius:30px;
      .nav_res_download {
        display: none;
       
        flex-direction: row;
       
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
      
        border-radius: 32px;
        cursor: pointer;
        @media screen and (max-width: 1035px) {
          display: flex;
          margin-right: 0;
        }
        @media screen and (max-width: 890px) {
         
        }
      }
    }
  }
  .nav_search2 {
    display: none;
    @media screen and (max-width: 600px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 3% 1.5%;
      width: 100%;
    }
  }
  .nav_search2_hide {
    display: none;
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
}
