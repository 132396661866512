.loader_wrapper {
  display: flex;
  justify-content: center;
  //color:white;

  .loading_img {
    width: 42px;
    height: 42px;
    @media screen and (max-width: 600px) {
      width: 20px;
      height: 20px;
    }
    .img_sz {
      width: 100%;
      height: 100%;
    }
  }

  #animation_rotate {
    animation: rotation 2s infinite linear;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
